.pageContainer {
    display: flex;
    height: 100vh;
    background-color: #fafafa;
    overflow: hidden;
}

.contentContainer {
    padding: 0px 35px 0px 50px;
    right: 0;
    width: 100%;
    margin: 10px auto;
    overflow-y: auto;
    margin-left: 85px;
}

.sideBarContainer {
    position: absolute;
    z-index: 50;
    background-color: #fff;
    border: none;
    box-shadow: 2px 2px 15px 5px rgba(82, 82, 82, 0.075);
    height: 97vh;
    width: 100px;
    transition: width 0.15s ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 25px;
    justify-content: flex-start;
    align-items: center;
    padding-top: 25px;
    border-radius: 0px 15px 15px 0px;
}

.sideBarContainerOpen {
    position: absolute;
    z-index: 50;
    background-color: #fff;
    border: none;
    box-shadow: 2px 2px 15px 5px rgba(82, 82, 82, 0.075);
    height: 97vh;
    width: 250px;
    transition: width 0.15s ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 25px;
    justify-content: flex-start;
    align-items: center;
    padding-top: 25px;
    border-radius: 0px 15px 15px 0px;
}


.sidebarLink {
    color: white;
    font-size: 14px;
    font-weight: 500;
    margin: 0px;
}

.linkContainer {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    transition: all 0.25s ease-in-out;
    border-radius: 0px 15px 15px 0px;
}

.linkContainer:hover {
    cursor: pointer;
    background-color: rgba(58, 180, 67, 0.15);
    transition: all 0.25s ease-in-out;
}

.linkContainerOpen {
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 10px 15px;
    border-radius: 10px;
    transition: background-color 0.25s ease-in-out;
}

.linkContainerOpen:hover {
    opacity: 100%;
    background-color: #ffffff22;
    transition: background-color 0.25s ease-in-out;
}

.userContainer {
    position: absolute;
    bottom: 0px;
    width: 100%;
}

.userContainer:hover {
    height: 260px;
}

.userIcon {
    width: 55px;
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.userIconOpen {
    width: 75px;
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 45%;
    margin-left: auto;
    margin-right: auto;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.userMgmtBtn {
    animation: fadeIn 0.15s;
    padding: 10px;
    background-color: rgba(58, 180, 67, 0.75);
    color: white;
    font-size: 18px;
    border-radius: 18px;
    position: absolute;
    bottom: 125px;

    width: 22px;
    height: 22px;
    transition: width 0.15s ease-in-out;
    z-index: 50;
    left: 25%;

    display: flex;
    align-items: center;
    cursor: pointer;
}

.userMgmtBtn:hover {
    transition: width 0.15s ease-in-out;
    width: 145px;
}

.userMgmtBtnOpen {
    animation: fadeIn 0.15s;
    padding: 10px;
    background-color: rgba(58, 180, 67, 0.75);
    color: white;
    font-size: 18px;
    border-radius: 18px;
    position: absolute;
    bottom: 125px;

    width: 145px;
    height: 22px;
    transition: width 0.15s ease-in-out;
    z-index: 50;
    left: 25%;

    display: flex;
    align-items: center;
    cursor: pointer;
}


.btnHoverText {
    margin-left: 10px;
    font-size: 14px;
    visibility: hidden;
}

.userMgmtBtn:hover .btnHoverText {
    transition: visibility 0s ease-in-out 0.15s;
    visibility: visible;
}

.userMgmtBtnOpen .btnHoverText {
    transition: visibility 0s ease-in-out 0.15s;
    visibility: visible;
}

.logoutBtn {
    animation: fadeIn 0.15s;
    padding: 10px;
    background-color: rgba(248, 125, 78, 0.75);
    color: white;
    font-size: 18px;
    border-radius: 18px;
    position: absolute;
    bottom: 70px;

    width: 22px;
    height: 22px;
    transition: width 0.15s ease-in-out;
    z-index: 50;
    left: 25%;

    display: flex;
    align-items: center;
    cursor: pointer;
}

.logoutBtn:hover {
    transition: width 0.15s ease-in-out;
    width: 105px;
}

.logoutBtnOpen {
    animation: fadeIn 0.15s;
    padding: 10px;
    background-color: rgba(248, 125, 78, 0.75);
    color: white;
    font-size: 18px;
    border-radius: 18px;
    position: absolute;
    bottom: 70px;

    width: 105px;
    height: 22px;
    transition: width 0.15s ease-in-out;
    z-index: 50;
    left: 25%;

    display: flex;
    align-items: center;
    cursor: pointer;
}


.logoutBtn:hover .btnHoverText {
    margin-left: 15px;
    transition: visibility 0s ease-in-out 0.15s;
    visibility: visible;
}

.logoutBtnOpen .btnHoverText {
    transition: visibility 0s ease-in-out 0.15s;
    visibility: visible;
}


.closeBtn {
    border-radius: 50px;
    width: 35px;
    height: 35px;
    padding: 0px;
    position: absolute;
    color: #888;
    font-size: 15px;
    top: 25px;
    right: 25px;
}


.widgetShadow {
    background: #FFFFFF;
    box-shadow: 0px 16px 44px rgba(82, 82, 82, 0.05);
    border-radius: 15px;
    padding: 15px 30px;
    transition: all 0.25s ease-in-out;
    width: 320px;
    padding: 25px 25px;
    border: none;
    border: 0.25px solid #00000022;

}

.widgetShadow:hover {
    cursor: pointer;
    border: 0.25px solid #00000055;
    box-shadow: 0px 16px 44px rgba(82, 82, 82, 0.15);
}